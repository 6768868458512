import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { Trans, Utils } from 'Helpers';
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';


class TeamShareModal extends Component {

  onCopyToggle = (msz) => {
    Utils.notify(msz, "success", 2000);
  }

  render() {
    const { isOpen, toggle, ScImage, disable, toggleClose } = this.props;
    const imageUrl = ScImage
    const shareTitle = `Your friend *${Utils.getProfile().user_name}* has invited you to join the Indian Super League fantasy season! Build your team, compete for the top spot, and win exciting prizes. Think you can beat them? Join now using the link below:\n`
    const shareUrl = imageUrl; 
    
    
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="custom-modal plc-modal share-team-modal"
        size="sm"
      >
        {!disable && <i className="close-btn left icon-cancel" onClick={toggleClose} />}
        <div className="title">
          <Trans>Share Team Via</Trans>
          
        </div>
        {disable && 
        <div className="loading">
        <div className="loading-text">
          <span className="loading-text-words">L</span>
          <span className="loading-text-words">O</span>
          <span className="loading-text-words">A</span>
          <span className="loading-text-words">D</span>
          <span className="loading-text-words">I</span>
          <span className="loading-text-words">N</span>
          <span className="loading-text-words">G</span>
        </div>
      </div>
        }
        <div className={`sharing-options ${disable ? 'dis-option' :''}`}>
          <button>
            <CopyToClipboard onCopy={() => this.onCopyToggle("Copied")} text={shareUrl}>
              <i className='icon-copy'></i>
            </CopyToClipboard>
          </button>
          

          <FacebookShareButton
            className="cursor-pointer"
            url={shareUrl}
            quote={shareTitle}
          >
             <i className="icon-fb"></i>
             
          </FacebookShareButton>
          <WhatsappShareButton
            className="cursor-pointer"
            url={shareUrl}
            title={shareTitle}
          >
             <i className="icon-whatsapp"></i>         
          </WhatsappShareButton>
          <TwitterShareButton
            className="cursor-pointer"
            url={shareUrl}
            title={shareTitle}
          >
             <i className="icon-tw"></i>         
          </TwitterShareButton>
        </div>
      </Modal>
    );
  }
}

TeamShareModal.defaultProps = {
  isOpen: false,
  toggle: () => {},
};

export default TeamShareModal;
